<template>
  <v-card>
    <div v-if="currentMappingCode">
      <v-card-title>Edit Mapping Code</v-card-title>
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="currentMappingCode.clientCode"
                outlined
                label="Client Code Elexys"
                required
                :rules="[v => !!v || 'Client Code Elexys is required']"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="currentMappingCode.clientCodeGenesis"
                outlined
                label="Client Code Genesis"
                required
                :rules="[v => !!v || 'Client Code Genesis is required']"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="currentMappingCode.clientID"
                outlined
                label="Client ID"
                required
                :rules="[v => !!v || 'Client ID is required']"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingCode.company"
                outlined
                label="Company"
                required
                :rules="[v => !!v || 'Company is required']"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingCode.city"
                outlined
                label="City"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingCode.username"
                outlined
                label="Username"
                required
                :rules="[v => !!v || 'Username is required']"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingCode.password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                outlined
                label="Password"
                required
                :rules="[v => !!v || 'Password is required']"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="currentMappingCode.webhook"
                outlined
                label="Webhook URL"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="currentMappingCode.webhookToken"
                outlined
                label="Webhook Token"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click.prevent="updateMappingCode"
          >
            Save
          </v-btn>
          <v-btn
            outlined
            class="mt-3"
            color="secondary"
            type="reset"
            @click.prevent="resetForm"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import MappingCodeService from '../../services/MappingCodeService'

export default {
  data() {
    return {
      isPasswordVisible: false,
      currentMappingCode: null,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted() {
    this.getMappingCode(this.$route.params.id)
  },
  methods: {
    getMappingCode(id) {
      MappingCodeService.get(id)
        .then(response => {
          this.currentMappingCode = response.data
        })
        .catch(e => {
          if (e.message.indexOf('404') !== -1) {
            this.$router.push({ name: 'not-found' })
          }
        })
    },
    updateMappingCode() {
      MappingCodeService.update(this.currentMappingCode.id, this.currentMappingCode)
        .then(() => {
          this.$router.push({ name: 'mapping-code' })
        })
        .catch(e => {
          console.log(e)
        })
    },
    resetForm() {
      this.$router.push({ name: 'mapping-code' })
    },
  },
}
</script>
